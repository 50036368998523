<template>
  <div class="layout">
    <div class="navTow" v-show="showNav">
      <img src="../assets/home/webTopPic2.png" alt=""/>
      <div class="right">
        <div class="navBox">
          <div
              style="display: flex;align-items: center;height: 60px;cursor: pointer;"
              v-for="(item, index) in nav"
              :key="index"
              @click="toUrl(item)"
              @mouseover="showPanel(item.name)"
          >{{ item.name }}
          </div>
          <div class="hover-panel" v-show="hoverShow" @mouseover="stayHover" @mouseleave="leaveHover">
            <el-card>
              <el-row :gutter="40" style="width: 900px;">
                <el-col :span="6" style="border-right: 1px solid #DBDBDB">
                  <div style="display: flex;align-items: center;margin-bottom: 10px;">
                    <div style="font-size: 14px;color: #D81E06;font-weight: 600;">贸易服务区</div>
                    <img src="../assets/home/open.png" style="width: 40px;margin-left: 5px;">
                  </div>
                  <div class="hover-panel-item" style="margin-bottom: 10px;cursor: pointer" @click="openUrl(1)"
                       v-for="(item, index) in tradeService"
                       :key="index">
                    <div>
                      <img :src="item.img" style="width: 30px;height: 30px;">
                    </div>
                    <div>
                      <div style="font-size: 14px;color: #333333">{{ item.name }}</div>
                      <div style="font-size: 12px;color: #999999">{{ item.subtitle }}</div>
                    </div>
                  </div>
                  <el-button type="warning" round size="mini" style="float: right" @click="openUrl(1)">查看更多
                  </el-button>
                </el-col>
                <el-col :span="6" style="border-right: 1px solid #DBDBDB">
                  <div style="display: flex;align-items: center;margin-bottom: 10px;">
                    <div style="font-size: 14px;color: #666666;font-weight: 600;">金融服务区</div>
                  </div>
                  <div class="hover-panel-item" style="margin-bottom: 10px;cursor: pointer;" @click="openUrl(1)"
                       v-for="(item, index) in financialService"
                       :key="index">
                    <div>
                      <img :src="item.img" style="width: 30px;height: 30px;">
                    </div>
                    <div>
                      <div style="font-size: 14px;color: #333333">{{ item.name }}</div>
                      <div style="font-size: 12px;color: #999999">{{ item.subtitle }}</div>
                    </div>
                  </div>
                  <el-button type="warning" round size="mini" style="float: right" @click="openUrl(2)">查看更多
                  </el-button>
                </el-col>
                <el-col :span="12">
                  <div style="font-size: 14px;color: #666666;font-weight: 600;margin-bottom: 10px;">综合业务区</div>
                  <div style="display: flex;flex-wrap: wrap;">
                    <div class="hover-panel-item" style="flex: 0 0 50%;margin-bottom: 10px;cursor: pointer;"
                         @click="openUrl(2)"
                         v-for="(item, index) in allService"
                         :key="index">
                      <div>
                        <img :src="item.img" style="width: 30px;height: 30px;">
                      </div>
                      <div>
                        <div style="font-size: 14px;color: #333333">{{ item.name }}</div>
                        <div style="font-size: 12px;color: #999999">{{ item.subtitle }}</div>
                      </div>
                    </div>
                  </div>
                  <el-button type="warning" round size="mini" style="float: right" @click="openUrl(2)">查看更多
                  </el-button>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </div>
        <div class="loginBntBox" @click="toLogin">
          <div>登录 / 注册</div>
        </div>
      </div>
    </div>
    <div class="header">
      <img src="../assets/home/webTopPic.png" alt=""/>
      <div class="right">
        <div class="navBox">
          <div
              style="display: flex;align-items: center;height: 60px;cursor: pointer;"
              v-for="(item, index) in nav"
              :key="index"
              @click="toUrl(item)"
              @mouseover="showPanel(item.name)"
          >{{ item.name }}
          </div>
          <div class="hover-panel" v-show="hoverShow" @mouseover="stayHover" @mouseleave="leaveHover">
            <el-card>
              <el-row :gutter="40" style="width: 900px;">
                <el-col :span="6" style="border-right: 1px solid #DBDBDB">
                  <div style="display: flex;align-items: center;margin-bottom: 10px;">
                    <div style="font-size: 14px;color: #D81E06;font-weight: 600;">贸易服务区</div>
                    <img src="../assets/home/open.png" style="width: 40px;margin-left: 5px;">
                  </div>
                  <div class="hover-panel-item" style="margin-bottom: 10px;cursor: pointer;" @click="openUrl(1)"
                       v-for="(item, index) in tradeService"
                       :key="index">
                    <div>
                      <img :src="item.img" style="width: 30px;height: 30px;">
                    </div>
                    <div>
                      <div style="font-size: 14px;color: #333333">{{ item.name }}</div>
                      <div style="font-size: 12px;color: #999999">{{ item.subtitle }}</div>
                    </div>
                  </div>
                  <el-button type="warning" round size="mini" style="float: right" @click="openUrl(1)">查看更多
                  </el-button>
                </el-col>
                <el-col :span="6" style="border-right: 1px solid #DBDBDB">
                  <div style="display: flex;align-items: center;margin-bottom: 10px;">
                    <div style="font-size: 14px;color: #666666;font-weight: 600;">金融服务区</div>
                  </div>
                  <div class="hover-panel-item" style="margin-bottom: 10px;cursor: pointer;" @click="openUrl(1)"
                       v-for="(item, index) in financialService"
                       :key="index">
                    <div>
                      <img :src="item.img" style="width: 30px;height: 30px;">
                    </div>
                    <div>
                      <div style="font-size: 14px;color: #333333">{{ item.name }}</div>
                      <div style="font-size: 12px;color: #999999">{{ item.subtitle }}</div>
                    </div>
                  </div>
                  <el-button type="warning" round size="mini" style="float: right" @click="openUrl(2)">查看更多
                  </el-button>
                </el-col>
                <el-col :span="12">
                  <div style="font-size: 14px;color: #666666;font-weight: 600;margin-bottom: 10px;">综合业务区</div>
                  <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                    <div class="hover-panel-item" style="flex: 0 0 50%;margin-bottom: 10px;cursor: pointer;"
                         @click="openUrl(2)"
                         v-for="(item, index) in allService"
                         :key="index">
                      <div>
                        <img :src="item.img" style="width: 30px;height: 30px;">
                      </div>
                      <div>
                        <div style="font-size: 14px;color: #333333">{{ item.name }}</div>
                        <div style="font-size: 12px;color: #999999">{{ item.subtitle }}</div>
                      </div>
                    </div>
                  </div>
                  <el-button type="warning" round size="mini" style="float: right" @click="openUrl(2)">查看更多
                  </el-button>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </div>
        <div class="loginBntBox" @click="toLogin">
          <div>登录 / 注册</div>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="footerStyle">
        <div class="footerBox">
          <div class="imgBox">
            <div @click="toFooterUrl('4')">
              <img src="../assets/home/zwlogo04.png" alt=""/>
              <p>中国政府网</p>
            </div>
            <div @click="toFooterUrl('3')">
              <img src="../assets/home/zwlogo03.png" alt=""/>
              <p>山东省人民政府网</p>
            </div>
            <div @click="toFooterUrl('1')">
              <img src="../assets/home/zwlogo01.png" alt=""/>
              <p>青岛政务网</p>
            </div>
            <div @click="toFooterUrl('2')">
              <img src="../assets/home/zwlogo02.png" alt=""/>
              <p>市南区政务网</p>
            </div>
          </div>
          <div class="borderStyle borderStyle2"></div>
          <div class="middle">
            <div>中马互贸创新综合服务平台</div>
            <p>
              联系地址：山东省青岛市市南区宁夏路288号青岛软件园11号楼A座611-29
            </p>
            <p>联系邮箱：service@mycntrade.com</p>
          </div>
          <div class="borderStyle borderStyle3"></div>
          <div class="QRcodeBox">
            <div>
              <img src="../assets/home/QRcode1.png" alt=""/>
              <p>爱山东</p>
            </div>
            <div>
              <img src="../assets/home/QRcode2.png" alt=""/>
              <p>青岛政务网</p>
            </div>
            <div>
              <img src="../assets/home/QRcode3.png" alt=""/>
              <p>中马互贸</p>
            </div>
          </div>
        </div>
        <div class="filings">
          <div>
            <img src="../assets/home/下载.png" alt=""/>
            <span @click="toFilings('1')">
                  鲁公网安备 37020202370206号 &nbsp;&nbsp;&nbsp;|
                <span @click="toFilings('2')">鲁ICP备2023021508号-2</span>
                </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import config from '@/config';

export default {
  data() {
    return {
      nav: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "综合服务大厅",
          path: "",
        },
        {
          name: "平台介绍",
          path: "/introduction",
        },
        {
          name: "动态要闻",
          path: "/guide",
        },
        {
          name: "技术赋能",
          path: "/technology",
        },
        {
          name: "联系方式",
          path: "/contactUs",
        },
      ],
      showNav: false,
      hoverShow: false,
      tradeService: [
        {img: require('../assets/home/trade1.png'), name: '产品展厅', subtitle: '中马优秀进出口商品展厅'},
        {img: require('../assets/home/trade2.png'), name: '企业名录', subtitle: '中马优秀会员企业名录'},
        {img: require('../assets/home/trade3.png'), name: '商机对接', subtitle: '中马商机洞查/贸易撮合'},
        {img: require('../assets/home/trade4.png'), name: '贸易数据', subtitle: '马来西亚外贸公报/贸易蓝图'},
      ],
      financialService: [
        {img: require('../assets/home/financial1.png'), name: '贸易融资', subtitle: '跨境融资/报关单融资'},
        {img: require('../assets/home/financial2.png'), name: '结算账户', subtitle: '跨境贸易支付账户设立'},
        {img: require('../assets/home/financial3.png'), name: '银行账户', subtitle: '中国及马来西亚银行开户'},
        {img: require('../assets/home/financial4.png'), name: '报关单贷款', subtitle: '基于报关单快速获得资金'},
      ],
      allService: [
        {img: require('../assets/home/all1.png'), name: '境内企业设立', subtitle: '中国青岛企业注册'},
        {img: require('../assets/home/all2.png'), name: '国际清真认证', subtitle: '伊斯兰国家贸易必备'},
        {img: require('../assets/home/all3.png'), name: '马来西亚企业设立', subtitle: '马来西亚企业注册'},
        {img: require('../assets/home/all4.png'), name: 'GCA国际认证', subtitle: '全球贸易通行证'},
        {img: require('../assets/home/all5.png'), name: '海关服务', subtitle: '海关与企业直连通道'},
        {img: require('../assets/home/all6.png'), name: 'RMD企业风险库', subtitle: '快速识别贸易风险'},
        {img: require('../assets/home/all7.png'), name: '全球企业信用报告', subtitle: '查询贸易对手信用情况'},
        {img: require('../assets/home/all8.png'), name: '商务签证', subtitle: '多次往返商务签'},
      ]
    };
  },
  mounted() {

    // let script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'https://static.chatbotkit.com/integrations/widget/v2.js';
    // script.id = "chatbotkit-widget";
    // script.setAttribute("data-widget", "clogza2je0036s30hu0d02u8p");
    // document.body.appendChild(script);


    window.addEventListener("scroll", () => {
      if (window.scrollY == 600 || window.scrollY > 600) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    });
  },
  methods: {
    toUrl(item) {
      if (!item.path) return;
      if (this.$route.fullPath == item.path) return;
      this.$router.push(item.path);
    },
    toFooterUrl(val) {
      if (val == "1") {
        window.open("http://www.qingdao.gov.cn/");
      }
      if (val == "2") {
        window.open("http://www.qdsn.gov.cn/");
      }
      if (val == "3") {
        window.open("http://www.shandong.gov.cn/");
      }
      if (val == "4") {
        window.open("https://www.gov.cn/");
      }
    },
    toLogin() {
      window.open("https://platform.mycntrade.com/");
    },
    toFilings(val) {
      if (val == '2') {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
      if (val == '1') {
        window.open('https://beian.mps.gov.cn/#/query/webSearch?code=37020202370206')
      }
    },
    showPanel(name) {
      if (name === '综合服务大厅') {
        setTimeout(() => {
          this.hoverShow = true;
        }, 600)
      } else {
        this.hoverShow = false;
      }
    },
    stayHover() {
      this.hoverShow = true;
    },
    leaveHover() {
      setTimeout(() => {
        this.hoverShow = false;
      }, 500)
    },
    openUrl(value) {
      if (value === 1) {
        const url = config.redirectUrl;
        window.open(url);
      } else if (value === 2) {
        window.open("https://platform.mycntrade.com/");
      }
    }
  },
};
</script>


<!--智能机器人-->
<!--<script id="chatbotkit-widget" src="https://static.chatbotkit.com/integrations/widget/v2.js" data-widget="clogqbbb20046mk0hwvk687vy"></script>-->


<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  @media screen and (min-width: 1401px) {
    padding: 0 140px;
  }
  padding: 0 5%;
  z-index: 9;

  .right {
    //width: 570px;
    width: 693px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;

    .navBox {
      height: 60px;
      //width: 416px;
      width: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;
      line-height: 22px;

      span {
        cursor: pointer;
      }
    }

    .loginBntBox {
      width: 93px;
      height: 27px;
      background: rgba(255, 255, 255);
      border-radius: 16px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      color: #2a50b7;
      cursor: pointer;
    }
  }
}

.main {
  min-height: calc(100vh - 273px);
}

.footer {
  background: #2d354d;
}

.footerStyle {
  background: #2d354d;
  margin: 0 auto;
  padding: 41px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerBox {
  display: flex;

  .imgBox {
    display: flex;
    padding-top: 9px;

    div {
      margin-right: 13px;
      cursor: pointer;

      p {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  .borderStyle {
    height: 107px;
    border: 1px solid #67718e;
    margin: 10px 20px 0 20px
  }

  .borderStyle2 {
    @media screen and (min-width: 1440px) {
      margin: 10px 25px 0 15px;
    }
  }

  .borderStyle3 {
    @media screen and (min-width: 1440px) {
      margin: 10px 33px 0 135px;
    }
  }

  .middle {
    color: #ffffff;
    line-height: 24px;
    font-weight: 400;
    margin-top: 9px;

    div {
      font-size: 22px;
    }

    p {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .QRcodeBox {
    display: flex;
    width: 360px;
    justify-content: space-between;
    text-align: center;
    color: #fff;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}

.filings {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;

  div {
    display: flex;
    align-items: center;

    img {
      margin-right: 2px;
    }

    span {
      cursor: pointer;
      margin: 0 10px;
    }
  }
}

.navTow {
  width: 100%;
  padding: 0 140px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  background: #ffffff;
  z-index: 9;
  box-shadow: 0px 1px 0px #dddddd;

  .right {
    //width: 570px;
    width: 693px;
    font-size: 16px;
    font-weight: 400;
    color: #2a50b7;
    display: flex;
    align-items: center;

    .navBox {
      height: 60px;
      //width: 416px;
      width: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;
      line-height: 22px;
    }

    .loginBntBox {
      width: 93px;
      height: 27px;
      background: #2a50b7;
      border-radius: 16px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.hover-panel {
  position: absolute;
  top: 50px;
  right: 200px;
  z-index: 100;
}

.hover-panel-item {
  display: flex;
}

::v-deep .el-card__body, .el-main {
  padding: 30px !important;
}
</style>
